"use client"

/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react"
import Link from "next/link"
import { XSMed } from "@/styles/Type"
import useAuth from "@/hooks/useAuth"
import AmpersandMarkIcon from "@/icons/AmpersandMark"
import Button from "@/components/Button"
import BurgerMenuIcon from "@/icons/BurgerMenu"
import { DashboardLink } from "@/components/SidebarMenu"
import { SidebarLink, SidebarParent } from "@/components/SidebarMenu/types"
import { usePathname } from "next/navigation"
import * as NavigationMenu from "@radix-ui/react-navigation-menu"
import MasqueradeSwitcher from "@/components/GlobalLayout/MasqueradeSwitcher"
import CompleteProfile from "../CompleteProfile"
import ChecklistModal from "../CompleteProfile/ChecklistModal"

const Sidebar = ({
  toggle,
  collapsed,
  links,
  theme,
}: {
  toggle: () => void
  collapsed: boolean
  links: (SidebarLink | SidebarParent)[]
  theme?: "dark" | "default"
}) => {
  const { user, masquerade } = useAuth()
  const pathname = usePathname()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [sideBarBorder, setSideBarBorder] = useState(false)
  useEffect(() => {
    if (pathname.includes("/brand/") || pathname.includes("/artist/")) {
      setSideBarBorder(true)
    } else {
      setSideBarBorder(false)
    }
  }, [pathname])
  const isMasqueradeAdmin = useMemo(() => {
    const myMasqueradeUser = masquerade?.active_users?.find(
      ({ email }) => email === user?.email
    )
    return (
      myMasqueradeUser?.access_type === "Admin" ||
      myMasqueradeUser?.access_type === "Owner"
    )
  }, [masquerade, user])

  const [menuValue, setMenuValue] = useState(
    links?.find(
      (link) =>
        ("href" in link && pathname.includes(link.href)) ||
        ("children" in link &&
          link.children.some((child) => pathname.includes(child.href)))
    )?.label
  )

  return (
    <>
      <aside
        id="default-sidebar"
        className={`fixed top-0 left-0 z-40 ${collapsed ? "w-[72px]" : "w-[249px]"} ${
          theme === "dark"
            ? `bg-offblack ${sideBarBorder ? "" : "border-r border-gunmetal"}`
            : `bg-spring ${sideBarBorder ? "" : "border-r"}`
        } h-screen transition-all -translate-x-full sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full">
          <div
            className={`sidebar-header flex items-center h-[54px] ${collapsed ? "justify-center pl-0" : "pl-[1.5rem]"}`}
          >
            <Button
              theme="custom"
              size="custom"
              onClick={toggle}
              as="button"
              className={`${collapsed ? "" : "mr-5"} ${
                theme === "dark"
                  ? "bg-offblack text-white"
                  : "text-gunmetal bg-spring"
              }`}
            >
              <BurgerMenuIcon />
            </Button>
            {!collapsed && (
              <Link
                href="/discover"
                passHref
                className={`${theme === "dark" ? "text-white" : "text-offblack"} hidden lg:block`}
              >
                <AmpersandMarkIcon width="42" height="22" />
                <span className="sr-only">R&amp;D</span>
              </Link>
            )}
          </div>
          <div className="flex flex-col justify-between h-[95%]">
            <div className="sidebar-content px-3 py-4 pt-[0]">
              <div
                className={`items-start mt-[17px] pt-[8px] px-3 pb-[20px] mb-[14px] ${
                  theme === "dark"
                    ? "bg-offblack text-white hover:bg-[rgba(255,255,255,0.04)] hover:rounded-lg"
                    : "text-gunmetal bg-spring hover:bg-[rgba(184,159,91,0.10)] hover:rounded-lg"
                } ${collapsed ? "!py-[4px] !px-[4px]" : "pl-3"}`}
              >
                <MasqueradeSwitcher collapsed={collapsed} />
              </div>
              <ul
                className={`space-y-2 font-medium ${theme === "dark" ? "text-white" : "text-gunmetal"}`}
              >
                <NavigationMenu.Root
                  asChild
                  orientation="vertical"
                  value={menuValue}
                >
                  <nav>
                    <NavigationMenu.List asChild>
                      <XSMed as="ul" className="group capitalize">
                        {links
                          ?.filter(
                            (link) =>
                              !link.hidden &&
                              (!link.adminOnly || isMasqueradeAdmin)
                          )
                          ?.map(({ ...props }) => (
                            <DashboardLink
                              mode={collapsed ? "onlyIcon" : "default"}
                              key={props.label}
                              setMenuValue={setMenuValue}
                              toggleSideBar={toggle}
                              theme={theme}
                              {...props}
                            />
                          ))}
                      </XSMed>
                    </NavigationMenu.List>
                  </nav>
                </NavigationMenu.Root>
              </ul>
            </div>
            {theme === "dark" && !collapsed && (
              <div className="hide-on-mobile">
                <CompleteProfile setIsDialogOpen={setIsDialogOpen} />
              </div>
            )}
          </div>
        </div>
      </aside>
      <ChecklistModal
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </>
  )
}

export default Sidebar
