import { ArtistOrBrand } from "@/types/ArtistOrBrand"
import Portfolio from "@/icons/Portfolio"
import Team, { TeamIcon } from "@/icons/Team"
import Settings from "@/icons/Cog"
import Notifications from "@/icons/Notifications"
import Star from "@/icons/Star"
import ProfileIcon from "@/icons/Profile"
import FolderIcon from "@/icons/Folder"
import Community from "@/icons/Community"
import React from "react"
import BookmarkAdmin from "@/icons/BookmarkAdmin"
import PortfolioActive from "@/icons/PortfolioActive"
import Globe from "@/icons/Globe"
import Inbox from "@/icons/Inbox"
import TeamIconActive from "@/icons/TeamActive"
import CommunityActive from "@/icons/CommunityActive"
import SettingsActive from "@/icons/CogActive"
import NotificationsActive from "@/icons/NotificationsActive"
import StarActive from "@/icons/StarActive"
import ProfileIconActive from "@/icons/ProfileActive"
import FolderIconActive from "@/icons/FolderActive"
import TeamIconActiveDark from "@/icons/TeamActiveDark"
import Applications from "@/icons/Applications"
import UsersIcon from "@/icons/Users"
import DealManagment from "@/icons/DealManagment"
import MessageIcon from "@/icons/MessageIcon"
import MessageIconDark from "@/icons/MessageIconDark"

export type HeaderConfig = {
  theme: "dark" | "default" | "bone" | "withoutBorder"
  children: React.ReactNode | undefined
}

export type LayoutConfig = {
  theme: "dark" | "default"
}

export type NavigationConfig = {
  theme: "dark" | "default"
} & (
  | {
      variant: "personalDashboard"
    }
  | {
      variant: "dashboard"
      masquerade: ArtistOrBrand
      inboundCount?: number
      outboundCount?: number
    }
  | {
      variant: "adminDashboard"
      masquerade: ArtistOrBrand
    }
)

const navVariantsMap = {
  dashboard: (masquerade, inboundCount, outboundCount) => {
    // const isMasqueradeDraft =
    //   !masquerade?.slug || masquerade?.status === "Draft"

    // const isRadIncUrl =
    //   typeof window !== "undefined" &&
    //   ["rad.inc", "www.rad.inc"].includes(window.location.hostname)

    return [
      {
        label: "Notifications",
        icon: Notifications,
        activeIcon: NotificationsActive,
        href: "/dashboard/notifications",
        // hidden: isMasqueradeDraft,
      },
      {
        label: "Inbox",
        icon: Inbox,
        activeIcon: Globe,
        href: "/dashboard/inbox",
        // hidden: isMasqueradeDraft || isRadIncUrl,
      },
      {
        label: "Discover",
        icon: Star,
        activeIcon: StarActive,
        href: "/discover",
        // hidden: isMasqueradeDraft,
      },
      {
        label: "Profile",
        // adminOnly: true,
        icon: ProfileIcon,
        activeIcon: ProfileIconActive,
        children: [
          {
            label: "Manage Profile",
            href: `/${masquerade?.type}/${masquerade?.slug}/edit`,
          },
          {
            label: "Manage Preferences",
            href: `/${masquerade?.type}/${masquerade?.slug}/preference/edit`,
          },
          {
            label: "Manage Visibility",
            href: "/dashboard/manage-visibility",
          },
          {
            label: "View Profile",
            href: `/${masquerade?.type}/${masquerade?.slug}`,
          },
        ],
      },
      {
        label: "Projects",
        icon: FolderIcon,
        activeIcon: FolderIconActive,
        href: "/dashboard/projects",
        // hidden: isMasqueradeDraft,
        children: [
          {
            label: `inbound [${inboundCount}]`,
            href: "/dashboard/projects",
          },
          {
            label: `outbound [${outboundCount}]`,
            href: "/dashboard/outbound",
          },
        ],
      },
      {
        label: "Bookmarks",
        icon: BookmarkAdmin,
        href: "/dashboard/bookmarks",
        // hidden: isMasqueradeDraft,
      },
      {
        label: `${masquerade?.type === "artist" ? "Talent" : "Brand"} Team`,
        icon: TeamIcon,
        activeIcon: TeamIconActiveDark,
        href: "/dashboard/admin",
        // hidden: isMasqueradeDraft,
      },
    ]
  },
  personalDashboard: () => {
    return [
      // {
      //   label: "Overview",
      //   icon: Portfolio,
      //   activeIcon: PortfolioActive,
      //   href: "/dashboard/overview",
      // },
      {
        label: "Portfolio",
        icon: Portfolio,
        activeIcon: PortfolioActive,
        href: "/dashboard/portfolio",
      },
      {
        label: "Messaging",
        icon: MessageIcon,
        activeIcon: MessageIconDark,
        href: "/dashboard/messaging",
      },
      {
        label: "Team",
        icon: Team,
        activeIcon: TeamIconActive,
        href: "/dashboard/team",
      },
      {
        label: "Community",
        icon: Community,
        activeIcon: CommunityActive,
        href: "/dashboard/community",
      },
      {
        label: "Settings",
        icon: Settings,
        activeIcon: SettingsActive,
        children: [
          {
            label: "Edit Profile",
            href: "/dashboard/settings",
          },
          {
            label: "Security",
            href: "/dashboard/security",
          },
        ],
      },
    ]
  },
  adminDashboard: () => {
    return [
      {
        label: "Applications",
        icon: Portfolio,
        activeIcon: Applications,
        href: "/admin/applications",
      },
      {
        label: "Users",
        icon: Team,
        activeIcon: UsersIcon,
        href: "/admin/users",
      },
      {
        label: "Groups",
        icon: Community,
        activeIcon: CommunityActive,
        href: "/admin/users",
      },
      {
        label: "Deal Managment",
        icon: DealManagment,
        activeIcon: DealManagment,
        href: "/admin/payments",
      },
      {
        label: "Profiles",
        icon: ProfileIcon,
        activeIcon: ProfileIconActive,
        href: "/admin/profiles",
      },
    ]
  },
}

export function buildNavigationLinks(config: NavigationConfig) {
  switch (config.variant) {
    case "dashboard":
      return navVariantsMap.dashboard(
        config.masquerade,
        config.inboundCount,
        config.outboundCount
      )
    case "personalDashboard":
      return navVariantsMap.personalDashboard()
    case "adminDashboard":
      return navVariantsMap.adminDashboard()
    default:
      return []
  }
}
