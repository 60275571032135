import type { SVGProps } from "react"

export const NotificationsActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.9993 6.66699C14.9993 5.34091 14.4725 4.06914 13.5349 3.13146C12.5972 2.19378 11.3254 1.66699 9.99932 1.66699C8.67324 1.66699 7.40147 2.19378 6.46379 3.13146C5.52611 4.06914 4.99932 5.34091 4.99932 6.66699C4.99932 9.24215 4.34972 11.0053 3.62404 12.1715C3.01193 13.1552 2.70587 13.6471 2.71709 13.7843C2.72952 13.9362 2.76171 13.9942 2.88414 14.085C2.99471 14.167 3.49315 14.167 4.49004 14.167H15.5086C16.5055 14.167 17.0039 14.167 17.1145 14.085C17.2369 13.9942 17.2691 13.9362 17.2816 13.7843C17.2928 13.6471 16.9867 13.1552 16.3746 12.1715C15.6489 11.0053 14.9993 9.24215 14.9993 6.66699Z"
      fill="#FFFFF4"
    />
    <path
      d="M11.666 17.5003H8.33266M14.9993 6.66699C14.9993 5.34091 14.4725 4.06914 13.5349 3.13146C12.5972 2.19378 11.3254 1.66699 9.99932 1.66699C8.67324 1.66699 7.40147 2.19378 6.46379 3.13146C5.52611 4.06914 4.99932 5.34091 4.99932 6.66699C4.99932 9.24215 4.34972 11.0053 3.62404 12.1715C3.01193 13.1552 2.70587 13.6471 2.71709 13.7843C2.72952 13.9362 2.76171 13.9942 2.88414 14.085C2.99471 14.167 3.49315 14.167 4.49004 14.167H15.5086C16.5055 14.167 17.0039 14.167 17.1145 14.085C17.2369 13.9942 17.2691 13.9362 17.2816 13.7843C17.2928 13.6471 16.9867 13.1552 16.3746 12.1715C15.6489 11.0053 14.9993 9.24215 14.9993 6.66699Z"
      stroke="#FFFFF4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default NotificationsActive
