/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Dialog from "@radix-ui/react-dialog"
import React, { useState, useEffect } from "react"

import Link from "next/link"
import Image from "next/image"
import { SerifH4, Small, XSSmall } from "@/styles/Type"
import UserAddIcon from "@/icons/UserAdd"
import CompleteQuestionIcon from "@/icons/CompleteQuotestion"
import FinalizeFrofileIcon from "@/icons/FinalizeFrofileIcon"
import PublishProfileIcon from "@/icons/PublishProfileIcon"
import ArrowUpRight from "@/icons/ArrowUpRight"
import useAuth from "@/hooks/useAuth"

const ChecklistModal = ({ isDialogOpen, setIsDialogOpen }) => {
  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    if (isDialogOpen) {
      setIsAnimating(true)
    } else {
      setIsAnimating(false)
    }
  }, [isDialogOpen])

  const { masquerade } = useAuth()
  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Overlay className="fixed z-40 inset-0 flex items-center justify-center bg-black bg-opacity-60" />
      <Dialog.Content className="w-[480px] fixed z-[70] animate-slide-in right-0 top-0">
        <div className="h-screen bg-[#EDEDE9] w-[480px] flex flex-col justify-start items-start overflow-hidden overflow-y-scroll border-r border-[#EEE]">
          <div className="py-6 pl-10 pr-5 border-b w-full  border-black border-opacity-20">
            <Dialog.Close className="w-6 h-6 p-0 flex items-center justify-center absolute top-[16px] right-[20px]">
              <Image
                className="mx-auto"
                alt="CloseButton"
                src="/assets/closebutton.svg"
                width="24"
                height="24"
                sizes="24px"
              />
            </Dialog.Close>
            <Dialog.Title asChild>
              <XSSmall>checklist</XSSmall>
            </Dialog.Title>
          </div>
          <div className="px-10 py-8 w-full">
            <SerifH4 className="text-offblack font-light text-[28px]">
              Complete your profile
            </SerifH4>
            <div className="flex items-center justify-start gap-4 text-offblack mt-16">
              <span className="text-[40px] rounded-full bg-white">
                <UserAddIcon />
              </span>
              <div className="py-4 border-b">
                <Small className="text-[#1E1E1E]">Establish your team</Small>
                <Small className="text-[#454542]">
                  Invite relevant team members to manage the profile and
                  associated deals.
                </Small>
              </div>
              <Link className="ml-auto text-[12px]" href="/dashboard/admin">
                <ArrowUpRight />
              </Link>
            </div>
            <div className="flex items-center justify-start gap-3 text-offblack mt-5">
              <span className="text-[40px] rounded-full bg-white">
                <CompleteQuestionIcon />
              </span>
              <div className="py-4 border-b">
                <Small className="text-[#1E1E1E] text-[16px]">
                  Complete questionnaires
                </Small>
                <Small className="text-[#454542]">
                  We use your answers to match you to the best collaborations
                  and collaborators.
                </Small>
              </div>
              <Link
                className="ml-auto text-[12px]"
                href={`/${masquerade?.type}/${masquerade?.slug}/preference/edit`}
              >
                <ArrowUpRight />
              </Link>
            </div>
            <div className="flex items-center justify-start gap-3 text-offblack mt-5">
              <span className="text-[40px] rounded-full bg-white">
                <FinalizeFrofileIcon />
              </span>
              <div className="py-4 border-b">
                <Small className="text-[#1E1E1E] text-[16px]">
                  Finalize your profile
                </Small>
                <Small className="text-[#454542]">
                  Your page allows other teams to learn about you and your
                  projects.
                </Small>
              </div>
              <Link
                className="ml-auto text-[12px]"
                href={`/${masquerade?.type}/${masquerade?.slug}/edit`}
              >
                <ArrowUpRight />
              </Link>
            </div>
            <div className="flex items-center justify-start gap-3 text-offblack mt-5 w-full">
              <span className="text-[40px] rounded-full bg-white">
                <PublishProfileIcon width={12} height={12} />
              </span>
              <div className="py-4 border-b">
                <Small className="text-[#1E1E1E] text-[16px]">
                  Publish your profile
                </Small>
                <Small className="text-[#454542]">
                  Publishing your profile makes both your profile page and
                  listed projects live and discoverable to other teams on the
                  platform.
                </Small>
              </div>
              <Link
                className="ml-auto text-[12px]"
                href="/dashboard/manage-visibility"
              >
                <ArrowUpRight />
              </Link>
            </div>
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default ChecklistModal
