export default function numberFormat(number: number, compact: boolean = true) {
  const numberFormatter = new Intl.NumberFormat("en-us", {
    maximumFractionDigits: 1,
    maximumSignificantDigits: compact ? 4 : 10,
    notation: compact ? "compact" : "standard",
  })
  return numberFormatter.format(number)
}

const currencyFormatter = new Intl.NumberFormat("en-us", {
  style: "currency",
  currency: "USD",
  notation: "compact",
  maximumFractionDigits: 0,
  maximumSignificantDigits: 4,
})

const longCurrencyFormatter = new Intl.NumberFormat("en-us", {
  style: "currency",
  currency: "USD",
})

export function shortCurrencyFormat(number: number) {
  return currencyFormatter.format(number)
}

export function currencyFormat(number: number) {
  return longCurrencyFormatter.format(number)
}

export function fileSizeFormatter(number: number) {
  const UNITS = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const exponent = Math.floor(Math.log(number) / Math.log(1024))

  return [Math.floor(number / 1024 ** exponent), UNITS[exponent]].join("")
}
