import { SVGProps } from "react"

export const FinalizeFrofileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="white" fillOpacity="0.85" />
    <path
      d="M22.8 26H16.6209C16.217 26 16.0151 26 15.9216 25.9201C15.8405 25.8508 15.7974 25.7469 15.8058 25.6405C15.8154 25.5179 15.9582 25.3751 16.2438 25.0895L21.9124 19.4209C22.1764 19.1569 22.3084 19.0249 22.4607 18.9754C22.5946 18.9319 22.7388 18.9319 22.8727 18.9754C23.0249 19.0249 23.1569 19.1569 23.4209 19.4209L26 22V22.8M22.8 26C23.9201 26 24.4802 26 24.908 25.782C25.2843 25.5903 25.5903 25.2843 25.782 24.908C26 24.4802 26 23.9201 26 22.8M22.8 26H17.2C16.0799 26 15.5198 26 15.092 25.782C14.7157 25.5903 14.4097 25.2843 14.218 24.908C14 24.4802 14 23.9201 14 22.8V17.2C14 16.0799 14 15.5198 14.218 15.092C14.4097 14.7157 14.7157 14.4097 15.092 14.218C15.5198 14 16.0799 14 17.2 14H22.8C23.9201 14 24.4802 14 24.908 14.218C25.2843 14.4097 25.5903 14.7157 25.782 15.092C26 15.5198 26 16.0799 26 17.2V22.8M19 17.6667C19 18.403 18.403 19 17.6667 19C16.9303 19 16.3333 18.403 16.3333 17.6667C16.3333 16.9303 16.9303 16.3333 17.6667 16.3333C18.403 16.3333 19 16.9303 19 17.6667Z"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FinalizeFrofileIcon
