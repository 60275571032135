import { useAppDispatch, useAppSelector } from "@/store/store"
import useAuth from "@/hooks/useAuth"
import { projectsSelectors } from "@/store/projects/selectors"
import React, { useCallback, useEffect } from "react"
import API from "@/lib/api"
import {
  InBoundProjectsResponse,
  projectActions,
} from "@/store/projects/projectsSlice"
import { InboundProject } from "@/types/Project"
import { Request } from "@/types/Request"
import { PROJECT_ARCHIVED_STATUSES } from "@/lib/constants"
import { REQUEST_STATUS, REQUEST_SUB_STATUS } from "@/types/RequestStatus"

let inboundAbort = new AbortController()
let outboundAbort = new AbortController()

let currentMasquerade = ""
export default function useProjects() {
  const dispatch = useAppDispatch()
  const { masquerade } = useAuth()

  const inboundProjects = useAppSelector(projectsSelectors.inboundProjects)
  const outboundProject = useAppSelector(projectsSelectors.outboundProject)

  const loadInboundProjects = useCallback(async () => {
    try {
      const res = await API.get<InBoundProjectsResponse>(
        "/api/dashboard/projects/",
        {
          signal: inboundAbort.signal,
        }
      )
      dispatch(projectActions.setInboundProjects(res.data))
    } catch (error) {
      console.error(error)
    }
  }, [dispatch])

  const loadOutboundProjects = useCallback(async () => {
    try {
      const res = await API.get<Request[]>(
        "/api/dashboad/request/outbound/project/",
        { signal: outboundAbort.signal }
      )
      dispatch(projectActions.setOutboundProjects(res.data))
    } catch (error) {
      console.error(error)
    }
  }, [dispatch])

  useEffect(() => {
    inboundAbort = new AbortController()
    outboundAbort = new AbortController()
    if (currentMasquerade !== masquerade?.slug && masquerade?.slug) {
      const load = async () => {
        try {
          await loadInboundProjects()
          await loadOutboundProjects()
          currentMasquerade = masquerade?.slug
        } catch (error) {
          console.error(error)
        }
      }
      load()
    }
    return () => {
      inboundAbort.abort()
      outboundAbort.abort()
    }
  }, [dispatch, loadInboundProjects, loadOutboundProjects, masquerade])

  const setInboundProjects = useCallback(
    (setData: (data?: InboundProject[]) => InboundProject[]) => {
      const updatedData = setData(inboundProjects?.projects)

      dispatch(
        projectActions.setInboundProjects({
          projects: updatedData,
          current_projects: updatedData?.filter(
            ({ status }) => !PROJECT_ARCHIVED_STATUSES.includes(status)
          )?.length,
          archived_projects: updatedData?.filter(({ status }) =>
            PROJECT_ARCHIVED_STATUSES.includes(status)
          )?.length,
        })
      )
    },
    [dispatch, inboundProjects?.projects]
  )

  const setOutBoundProjects = useCallback(
    (setData: (data: Request[]) => Request[]) => {
      dispatch(projectActions.setOutboundProjects(setData(outboundProject)))
    },
    [dispatch, outboundProject]
  )

  return React.useMemo(() => {
    return {
      inboundProjects,
      outboundProject: outboundProject?.filter((p) => {
        if (
          [REQUEST_STATUS.NEW, REQUEST_STATUS.NEW_REQUEST].includes(p.status)
        ) {
          return false
        }
        return !(
          p.status === REQUEST_STATUS.IN_CONVERSATION &&
          [REQUEST_SUB_STATUS.GRANTED, REQUEST_SUB_STATUS.NEW].includes(
            p.sub_status
          )
        )
      }),
      setInboundProjects,
      setOutBoundProjects,
      loadInboundProjects,
      loadOutboundProjects,
    }
  }, [
    inboundProjects,
    outboundProject,
    setInboundProjects,
    setOutBoundProjects,
    loadInboundProjects,
    loadOutboundProjects,
  ])
}
