import clsx from "clsx"
import { TextareaProps } from "./types"
import { Input } from "./Input"

export const TextareaInput = ({
  onChange,
  maxLength,
  ...props
}: TextareaProps) => {
  // const charCount = useMemo(() => {
  //   if (typeof props.value === "string") {
  //     return props.value.length
  //   }
  //   return 0
  // }, [props.value])

  return (
    <div className="relative">
      <Input
        {...props}
        as="textarea"
        className={clsx("resize-y", props.className)}
        maxLength={maxLength}
        value={props.value}
        onChange={onChange}
        rows={
          props.rows || Math.min(Math.max(Math.ceil(maxLength / 80), 3), 10)
        }
      />
      {/* {maxLength && ( */}
      {/*  <span className="absolute bottom-0 right-3 text-gray-400 text-xs"> */}
      {/*    {charCount}/{maxLength} */}
      {/*  </span> */}
      {/* )} */}
    </div>
  )
}

export default TextareaInput
