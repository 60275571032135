"use client"

import GptChatModalTrigger from "@/components/GptChat/GptChatModalTrigger"
import GptChatModal from "@/components/GptChat/GptChatModal"

const GptChat = () => {
  return (
    <>
      <GptChatModalTrigger />
      <GptChatModal />
    </>
  )
}

export default GptChat
