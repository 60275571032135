import type { FC, SVGProps } from "react"

export const Globe: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M18.3337 10H13.3337L11.667 12.5H8.33366L6.66699 10H1.66699"
      stroke="#FFFFF4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.54199 4.25898L1.66699 10.0007V15.0007C1.66699 15.4427 1.84259 15.8666 2.15515 16.1792C2.46771 16.4917 2.89163 16.6673 3.33366 16.6673H16.667C17.109 16.6673 17.5329 16.4917 17.8455 16.1792C18.1581 15.8666 18.3337 15.4427 18.3337 15.0007V10.0007L15.4587 4.25898C15.3207 3.98131 15.108 3.74763 14.8445 3.58422C14.5809 3.42081 14.2771 3.33415 13.967 3.33398H6.03366C5.72359 3.33415 5.41971 3.42081 5.1562 3.58422C4.89268 3.74763 4.67997 3.98131 4.54199 4.25898Z"
      stroke="#FFFFF4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default Globe
