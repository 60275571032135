import { useEffect, useState } from "react"

const MAX_RECENT_MASQUERADE_IDS = 3
const RECENT_MASQUERADES_KEY = "recent_masquerades"

export const useRecentMasqueradeIds = () => {
  const [recentMasqueradeIds, updateRecentMasqueradeId] = useState<number[]>([])

  useEffect(() => {
    const storedMasqueradeIds = localStorage.getItem(RECENT_MASQUERADES_KEY)
    if (storedMasqueradeIds) {
      updateRecentMasqueradeId(JSON.parse(storedMasqueradeIds))
    }
  }, [])

  const insertMasqueradeId = (newId: number) => {
    const updatedMasquerades = [
      ...recentMasqueradeIds.filter((id) => newId != id),
      newId,
    ]
    if (updatedMasquerades.length > MAX_RECENT_MASQUERADE_IDS) {
      updatedMasquerades.shift()
    }
    updateRecentMasqueradeId(updatedMasquerades)
    localStorage.setItem(
      RECENT_MASQUERADES_KEY,
      JSON.stringify(updatedMasquerades)
    )
  }

  return [recentMasqueradeIds.reverse(), insertMasqueradeId] as const
}
