import type { SVGProps } from "react"

export const FolderIconActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.66602 5.83333H14.3327C15.7328 5.83333 16.4329 5.83333 16.9677 6.10582C17.4381 6.3455 17.8205 6.72795 18.0602 7.19836C18.3327 7.73314 18.3327 8.4332 18.3327 9.83333V13.5C18.3327 14.9001 18.3327 15.6002 18.0602 16.135C17.8205 16.6054 17.4381 16.9878 16.9677 17.2275C16.4329 17.5 15.7328 17.5 14.3327 17.5H5.66602C4.26588 17.5 3.56582 17.5 3.03104 17.2275C2.56063 16.9878 2.17818 16.6054 1.9385 16.135C1.66602 15.6002 1.66602 14.9001 1.66602 13.5V5.83333Z"
      fill="#FFFFF4"
    />
    <path
      d="M10.8327 5.83333L9.90306 3.9741C9.63552 3.439 9.50174 3.17144 9.30216 2.97597C9.12566 2.80311 8.91295 2.67164 8.67941 2.59109C8.41532 2.5 8.11619 2.5 7.51792 2.5H4.33268C3.39926 2.5 2.93255 2.5 2.57603 2.68166C2.26243 2.84144 2.00746 3.09641 1.84767 3.41002C1.66602 3.76654 1.66602 4.23325 1.66602 5.16667V5.83333M1.66602 5.83333H14.3327C15.7328 5.83333 16.4329 5.83333 16.9677 6.10582C17.4381 6.3455 17.8205 6.72795 18.0602 7.19836C18.3327 7.73314 18.3327 8.4332 18.3327 9.83333V13.5C18.3327 14.9001 18.3327 15.6002 18.0602 16.135C17.8205 16.6054 17.4381 16.9878 16.9677 17.2275C16.4329 17.5 15.7328 17.5 14.3327 17.5H5.66602C4.26588 17.5 3.56582 17.5 3.03104 17.2275C2.56063 16.9878 2.17818 16.6054 1.9385 16.135C1.66602 15.6002 1.66602 14.9001 1.66602 13.5V5.83333Z"
      stroke="#FFFFF4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default FolderIconActive
