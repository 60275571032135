import React from "react"
import Button from "@/components/Button"
import MessageCircle from "@/icons/MessageCircle"
import { useAppDispatch } from "@/store/store"
import { commonActions } from "@/store/common/commonSlice"
import REDUX_MODAL_KEYS from "@/lib/modals"

const GptChatModalTrigger = React.memo(() => {
  const dispatch = useAppDispatch()

  const handleOpen = () => {
    dispatch(
      commonActions.setModal({ key: REDUX_MODAL_KEYS.gptChat, visible: true })
    )
  }

  return (
    <Button
      onClick={handleOpen}
      theme="custom"
      size="custom"
      className="!fixed right-[80px] top-[15px] z-[30] cursor-pointer "
    >
      <MessageCircle className="text-offblack" />
    </Button>
  )
})

GptChatModalTrigger.displayName = "GptChatModalTrigger"

export default GptChatModalTrigger
