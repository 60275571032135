import type { SVGProps } from "react"

export const InboxIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M17.1113 9.5105H12.6113L11.1113 11.7605H8.11133L6.61133 9.5105H2.11133M17.1113 9.5105V14.0105C17.1113 14.4083 16.9533 14.7899 16.672 15.0712C16.3907 15.3525 16.0092 15.5105 15.6113 15.5105H3.61133C3.2135 15.5105 2.83197 15.3525 2.55067 15.0712C2.26936 14.7899 2.11133 14.4083 2.11133 14.0105V9.5105M17.1113 9.5105L14.5238 4.343C14.3996 4.09309 14.2082 3.88278 13.971 3.73571C13.7339 3.58864 13.4604 3.51065 13.1813 3.5105H6.04133C5.76226 3.51065 5.48878 3.58864 5.25161 3.73571C5.01445 3.88278 4.82301 4.09309 4.69883 4.343L2.11133 9.5105" />
  </svg>
)
export default InboxIcon
