import type { FC, SVGProps } from "react"

export const TeamIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M13.3334 2.8898C14.5681 3.50343 15.4167 4.77762 15.4167 6.25C15.4167 7.72238 14.5681 8.99657 13.3334 9.6102M15 13.972C16.2596 14.5419 17.3938 15.4708 18.3334 16.6667M1.66669 16.6667C3.28876 14.6021 5.491 13.3333 7.91669 13.3333C10.3424 13.3333 12.5446 14.6021 14.1667 16.6667M11.6667 6.25C11.6667 8.32107 9.98775 10 7.91669 10C5.84562 10 4.16669 8.32107 4.16669 6.25C4.16669 4.17893 5.84562 2.5 7.91669 2.5C9.98775 2.5 11.6667 4.17893 11.6667 6.25Z" />
  </svg>
)
export default TeamIcon
