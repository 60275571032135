import React, { useEffect, useCallback } from "react"
// eslint-disable-next-line import/no-extraneous-dependencies
import { useAssistant } from "@ai-sdk/react"
import Textarea from "@/components/Forms/Textarea"
import { LoadingChannel } from "stream-chat-react/dist/components/Channel/LoadingChannel"
import useAuth from "@/hooks/useAuth"
import Image from "@/components/Image"
import * as Dialog from "@radix-ui/react-dialog"
import CloseIcon from "@/icons/Close"
import Button from "../Button"

const GptChat = React.memo(() => {
  const scrollerRef = React.useRef<HTMLDivElement>(null)
  const { user } = useAuth()
  const { messages, status, error, append, input, handleInputChange } =
    useAssistant({
      api: "/lumina/gpt",
    })

  useEffect(() => {
    if (error) {
      console.error("AI Assistant Error:", error)
    }
  }, [error])

  useEffect(() => {
    if (messages) {
      scrollerRef.current?.scrollTo({
        top: scrollerRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }, [messages])

  const handleSubmit = useCallback(
    (
      e:
        | React.FormEvent<HTMLFormElement>
        | React.KeyboardEvent<HTMLTextAreaElement>
    ) => {
      e.preventDefault()
      if (input.trim()) {
        append({
          role: "user",
          content: input.trim(),
        })
      }
    },
    [append, input]
  )

  return (
    <div className="flex flex-col h-screen w-[658px] border border-gray-300 rounded-lg shadow-lg bg-white">
      {/* Header */}
      <div className="p-4 border-b border-gray-200 flex items-center justify-between">
        <div className="flex items-center">
          <Image
            src="/iconrd-512.png"
            alt="Avatar"
            width={32}
            height={32}
            className="h-8 w-8 rounded-full mr-3"
          />
          <div className="flex flex-col">
            <h2 className="text-offblack font-semibold">Chat with Lumina</h2>
            <span className="text-sm text-medgray">
              Send messages, attachments, and more
            </span>
          </div>
        </div>

        <Dialog.Close className="">
          <CloseIcon className="text-offblack" />
        </Dialog.Close>
      </div>

      {/* Messages */}
      <div
        className="flex-1 overflow-y-auto p-4 space-y-6 bg-gray-50"
        ref={scrollerRef}
      >
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.role === "assistant" ? "flex-row" : "flex-row-reverse"
            } items-start space-x-3 gap-4`}
          >
            <div className="flex flex-col items-center flex-shrink-0">
              <Image
                width={32}
                height={32}
                src={
                  message.role === "assistant"
                    ? "/iconrd-512.png"
                    : `${user?.profile_image}`
                }
                alt="User Avatar"
                className="h-8 w-8 rounded-full object-cover"
              />
              <span className="text-xs text-gray-500 mt-1">
                {message.role === "assistant"
                  ? "rad AI"
                  : `${user?.first_name || ""} ${user?.last_name || ""}`.trim() ||
                    "You"}
              </span>
            </div>
            <div>
              <div
                className={`p-3 rounded-lg ${
                  message.role === "assistant"
                    ? "bg-[#ededed] text-black"
                    : "bg-[#ededed] text-black"
                }`}
              >
                <p className="text-sm">{message.content}</p>
              </div>
              <span className="text-xs text-gray-500">
                {new Date().toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </div>
          </div>
        ))}
        {status === "in_progress" && (
          <div className="flex justify-center">
            <LoadingChannel />
          </div>
        )}
      </div>

      {/* Input */}
      <form
        className="flex items-center p-4 border-t border-gray-200 bg-white"
        onSubmit={handleSubmit}
      >
        <div className="w-full">
          <Textarea
            placeholder="Message Collaboration Opportunity..."
            value={input}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm resize-none focus:ring focus:ring-blue-200"
            rows={1}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                handleSubmit(e)
              }
            }}
          />
        </div>
        <Button type="submit" theme="primary" className="ml-2 ">
          Send
        </Button>
      </form>
    </div>
  )
})

export default GptChat
