"use client"

import React from "react"
import useAuth from "@/hooks/useAuth"
import Image from "next/image"
import { useAppSelector } from "@/store/store"
import { authSelectors } from "@/store/auth/selectors"
import { SerifH5 } from "@/styles/Type"

const Avatar = ({ src, alt, fallbackText }) => {
  if (src) {
    return (
      <Image
        className="w-[48px] h-[48px] object-cover mr-[15px] rounded-full"
        src={src}
        alt={alt}
        width="48"
        height="48"
        sizes="48px"
      />
    )
  }
  return (
    <div className="w-[40px] h-[40px] bg-swisscafe rounded-full flex justify-center items-center mr-2">
      <SerifH5 className="mt-1">{fallbackText}</SerifH5>
    </div>
  )
}

const MasqueradeSwitchLoader = React.memo(() => {
  const isLoading = useAppSelector(authSelectors.isLoadingMasqueradeSwitch)
  const { masquerade, user } = useAuth()

  if (!isLoading) {
    return null
  }

  const displayName = masquerade?.type
    ? masquerade?.name
    : `${user?.first_name} ${user?.last_name}`
  const profileImage = masquerade?.type
    ? masquerade?.hero?.image_src
    : user?.profile_image

  return (
    <div
      className="bg-[rgba(22,22,22,1)] text-black h-screen flex justify-center items-center fixed w-full right-0 top-0 z-[99]"
      role="alert"
      aria-live="polite"
    >
      <div>
        <Image
          className="mx-auto"
          alt="Loading"
          src="/assets/rad-gif/rad-logo-gif.gif"
          width="37"
          height="27"
          sizes="40px"
        />
        <p className="text-cream text-center mt-[16px] mb-[16px] tracking-wide uppercase text-[10px]">
          Switching to
        </p>
        <div className="flex items-center">
          <Avatar
            src={profileImage}
            alt={displayName}
            fallbackText={
              masquerade?.name?.charAt(0) || user?.first_name?.charAt(0)
            }
          />
          <p className="text-white text-[19px]">{displayName}</p>
        </div>
      </div>
    </div>
  )
})

export default MasqueradeSwitchLoader
