import { SVGProps } from "react"

export const UserAddIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="white" fillOpacity="0.85" />
    <path
      d="M24.6663 26V22M22.6663 24H26.6663M19.9997 22H17.333C16.0905 22 15.4692 22 14.9792 22.203C14.3258 22.4736 13.8066 22.9928 13.536 23.6462C13.333 24.1362 13.333 24.7575 13.333 26M22.333 14.1938C23.3103 14.5894 23.9997 15.5475 23.9997 16.6667C23.9997 17.7858 23.3103 18.7439 22.333 19.1395M20.9997 16.6667C20.9997 18.1394 19.8058 19.3333 18.333 19.3333C16.8602 19.3333 15.6663 18.1394 15.6663 16.6667C15.6663 15.1939 16.8602 14 18.333 14C19.8058 14 20.9997 15.1939 20.9997 16.6667Z"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default UserAddIcon
