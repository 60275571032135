import type { SVGProps } from "react"

export const DealManagment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8092 5.33339H14.022C14.7036 5.33339 15.2456 5.33338 15.6829 5.36911C16.1307 5.4057 16.5125 5.48223 16.8621 5.66037C17.4266 5.94799 17.8856 6.40693 18.1732 6.97142C18.3513 7.32104 18.4279 7.70288 18.4644 8.15068C18.5002 8.58794 18.5002 9.12991 18.5002 9.81137V13.5219C18.5002 14.2034 18.5002 14.7455 18.4644 15.1828C18.4279 15.6306 18.3513 16.0124 18.1732 16.362C17.8856 16.9265 17.4266 17.3855 16.8621 17.6731C16.5125 17.8512 16.1307 17.9277 15.6829 17.9643C15.2456 18.0001 14.7035 18.0001 14.022 18.0001H5.31166C4.63012 18.0001 4.08809 18.0001 3.65079 17.9643C3.20299 17.9277 2.82115 17.8512 2.47153 17.6731C1.90704 17.3855 1.4481 16.9265 1.16048 16.362C0.982335 16.0124 0.905804 15.6306 0.869218 15.1828C0.83349 14.7455 0.833493 14.2035 0.833496 13.522V5.14578C0.833491 4.69687 0.833486 4.32972 0.857864 4.03135C0.883097 3.7225 0.936921 3.44357 1.06965 3.18308C1.27737 2.77539 1.60883 2.44394 2.01652 2.23621C2.27701 2.10348 2.55594 2.04966 2.86478 2.02443C3.16316 2.00005 3.5303 2.00005 3.97922 2.00006L7.26509 2.00003C7.78569 1.99973 8.16505 1.99952 8.50993 2.11848C8.81353 2.2232 9.09006 2.3941 9.3195 2.61882C9.58013 2.87409 9.7496 3.21351 9.98216 3.67928L10.8092 5.33339ZM7.18541 3.00006C7.81992 3.00006 8.01791 3.00658 8.18386 3.06382C8.34733 3.12021 8.49623 3.21223 8.61978 3.33324C8.74519 3.45607 8.83957 3.63025 9.12333 4.19776L9.69115 5.33339H1.8335V5.16673C1.8335 4.69176 1.83389 4.36561 1.85454 4.11278C1.87472 3.86578 1.91173 3.7331 1.96066 3.63707C2.07251 3.41755 2.25099 3.23907 2.47051 3.12722C2.56653 3.07829 2.69922 3.04129 2.94622 3.0211C3.19905 3.00045 3.5252 3.00006 4.00016 3.00006H7.18541Z"
      fill="currentColor"
    />
  </svg>
)
export default DealManagment
