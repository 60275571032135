import { SVGProps } from "react"

export const CompleteQuestionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="white" fillOpacity="0.85" />
    <path
      d="M19.9997 14.0026H22.7997C23.9198 14.0026 24.4798 14.0026 24.9077 14.2206C25.284 14.4123 25.5899 14.7183 25.7817 15.0946C25.9997 15.5224 25.9997 16.0825 25.9997 17.2026V22.8026C25.9997 23.9227 25.9997 24.4828 25.7817 24.9106C25.5899 25.2869 25.284 25.5929 24.9077 25.7846C24.4798 26.0026 23.9198 26.0026 22.7997 26.0026H17.1997C16.0796 26.0026 15.5195 26.0026 15.0917 25.7846C14.7154 25.5929 14.4094 25.2869 14.2177 24.9106C13.9997 24.4828 13.9997 23.9227 13.9997 22.8026V20.0026M17.333 20.6693V23.3359M22.6663 19.3359V23.3359M19.9997 16.6693V23.3359M15.333 17.3359V13.3359M13.333 15.3359H17.333"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CompleteQuestionIcon
