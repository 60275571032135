import { SVGProps } from "react"

export const BookmarkAdmin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.16669 17.5V4.5C4.16669 3.39543 5.06212 2.5 6.16669 2.5H13.8334C14.9379 2.5 15.8334 3.39543 15.8334 4.5V17.5L11.0815 14.4453C10.4227 14.0217 9.57732 14.0217 8.9185 14.4453L4.16669 17.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default BookmarkAdmin
