import type { SVGProps } from "react"

export const CommunityActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.09706 9.48398H2C2.23939 5.65132 5.12681 2.54344 8.83813 2C7.21049 4.15983 6.25304 6.76482 6.09706 9.48398Z"
      fill="#272727"
    />
    <path
      d="M7.12224 9.48398C7.28856 6.80416 8.29692 4.24719 10 2.1845C11.7031 4.24719 12.7114 6.80416 12.8778 9.48398H7.12224Z"
      fill="#272727"
    />
    <path
      d="M10 17.8155C11.7031 15.7528 12.7114 13.1958 12.8778 10.516H7.12224C7.28856 13.1958 8.29692 15.7528 10 17.8155Z"
      fill="#272727"
    />
    <path
      d="M2 10.516H6.09706C6.25304 13.2352 7.21049 15.8402 8.83813 18C5.12681 17.4566 2.23939 14.3487 2 10.516Z"
      fill="#272727"
    />
    <path
      d="M13.9029 10.516C13.747 13.2352 12.7895 15.8402 11.1619 18C14.8732 17.4566 17.7606 14.3487 18 10.516H13.9029Z"
      fill="#272727"
    />
    <path
      d="M13.9029 9.48398H18C17.7606 5.65132 14.8732 2.54344 11.1619 2C12.7895 4.15983 13.747 6.76483 13.9029 9.48398Z"
      fill="#272727"
    />
  </svg>
)
export default CommunityActive
