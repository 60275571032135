/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
import { H3, XSmall } from "@/styles/Type"
import { ArrowRightIcon } from "@/icons/ArrowRight"
import React from "react"
import { CloseIcon } from "@/icons/Close"

import clsx from "clsx"

import useAuth from "@/hooks/useAuth"
import Button from "../Button"

const index = ({ setIsDialogOpen }) => {
  const { user, masquerade } = useAuth()

  const mileStones = [
    { id: 1, completed: masquerade?.status === "Approved" },
    { id: 2, completed: masquerade?.status === "Approved" },
    { id: 3, completed: user?.enable_2fa },
    { id: 4, completed: user?.teams.length > 0 },
    {
      id: 5,
      completed:
        user?.artist_profiles.length > 0 || user?.brand_profiles.length > 0,
    },
  ].sort((a, b) => (b.completed ? 1 : -1))

  const allMilestonesCompleted = mileStones.every(
    (milestone) => milestone.completed
  )

  return (
    <>
      <div className="p-2">
        <div className="border-[1px] rounded-md border-charcoal p-3 relative w-full ">
          <CloseIcon className="absolute cursor-pointer top-2 right-2 sr-only" />
          <H3 as="a" className="text-cream mb-2 font-medium">
            {allMilestonesCompleted
              ? "Profile Completed!"
              : "Complete your profile"}
          </H3>
          <div className="flex gap-1 mt-2">
            {mileStones.map((milestone, index) => (
              <div
                key={milestone.id}
                className={clsx(
                  "w-[39px] h-[9px]",
                  index === 0 ? "rounded-l-md" : "",
                  index === mileStones.length - 1 ? "rounded-r-md" : "",
                  milestone.completed ? "bg-gold" : "bg-charcoal"
                )}
              />
            ))}
          </div>
          <div className="mt-2">
            <XSmall className="text-medgray">
              Start promoting your profile by completing all these steps.
            </XSmall>
          </div>
          {allMilestonesCompleted ? null : (
            <Button
              className="mt-6 mb-2 text-bronze font-medium"
              theme="custom"
              size="custom"
              onClick={() => setIsDialogOpen(true)}
            >
              Checklist
              <ArrowRightIcon stroke="" className="ml-2" />
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default index
