/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

"use client"

import React, { FC, MouseEventHandler, useMemo, useState } from "react"
import { usePathname, useRouter } from "next/navigation"
import Link from "next/link"
import clsx from "clsx"
import * as NavigationMenu from "@radix-ui/react-navigation-menu"

import createStyle from "@/lib/createStyle"
import AngleDownIcon from "@/icons/AngleDown"
import TooltipWrapper from "@/components/Tooltip"

import { XSMed } from "@/styles/Type"
import useAuth from "@/hooks/useAuth"

import {
  SidebarLink,
  SidebarParent,
  SidebarMenuProps,
  SidebarFooterProps,
} from "./types"

export const DashboardSidebar = createStyle(
  "aside",
  "col-span-3 bg-black xl:col-span-3 w-[241px] lg:col-span-3 overflow-scroll no-scrollbar text-gunmetal pt-7 hidden lg:flex flex-col justify-between h-[calc(100vh-3rem)] sticky top-[56px] border-r border-buff",
  { displayName: "DashboardSidebar" }
)

export const LinkParent = createStyle(
  "div",
  clsx(
    "flex items-center py-3 tracking-normal",
    "w-full",
    "rounded",
    "transition duration-150 cursor-pointer"
  )
)

export const ChildLink = createStyle(
  "a",
  "block rounded py-2.5 pl-12 pr-5 capitalize flex justify-between text-[#6D6D6D] hover:text-[#272727] tracking-normal hover:bg-[rgba(255,255,255,0.04)] transition duration-150"
)

export const DashboardLink: FC<
  (SidebarLink | SidebarParent) & {
    setMenuValue: (v: string) => void
    mode?: "onlyIcon" | "default"
    toggleSideBar?: () => void
    theme?: "default" | "dark"
  }
> = ({
  label,
  icon: Icon,
  activeIcon: ActiveIcon,
  setMenuValue,
  mode = "default",
  toggleSideBar,
  theme = "dark",
  ...props
}) => {
  const pathname = usePathname()
  const router = useRouter()
  const [subnav, setSubnav] = useState(false)

  const isLink = "href" in props && !!props.href
  const isParent = "children" in props && !!props.children?.length

  const parentHasHref = isParent && isLink

  // @ts-ignore
  const isActiveLinkParent = props.children?.some((child) => {
    return child?.href && pathname.includes(child.href)
  })

  const isActive = useMemo(() => {
    if (isLink && pathname.includes(props.href as string)) {
      return true
    }
    if (isActiveLinkParent) {
      return true
    }
    return false
  }, [isLink, props.href, pathname, isActiveLinkParent])

  const NavIcon = useMemo(() => {
    if (isActive && ActiveIcon) {
      return ActiveIcon
    }
    return Icon
  }, [Icon, ActiveIcon, isActive])

  const handleNav: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!e.currentTarget.href) {
      return
    }
    e.preventDefault()
    router.push(e.currentTarget.href)
  }

  const toggleSubnav = (e: React.MouseEvent) => {
    e.stopPropagation()
    setSubnav((prev) => !prev)
  }

  const onParentClick = (e: React.MouseEvent) => {
    if (mode === "onlyIcon") {
      toggleSideBar?.()
    }
    setMenuValue(label)

    if (parentHasHref) {
      e.preventDefault()
      router.push(props.href as string)
    }
  }

  if (!isParent) {
    return (
      <Link href={props.href as string} passHref legacyBehavior>
        <LinkParent
          className={clsx(
            isActive &&
              (theme === "default"
                ? "bg-[rgba(184,159,91,0.20)] !text-[#272727]"
                : "bg-[rgba(255,255,255,0.08)] !text-[#FFFFF4]"),
            theme === "default" ? "" : "hover:text-[#FFFFF4]",
            mode === "default" &&
              "px-3 text-[#6D6D6D] pt-3.5 pr-3 pb-3.25 pl-3 w-[225px] h-[47px] hover:text-[#272727]",
            mode === "onlyIcon" &&
              "justify-center text-[#6D6D6D] hover:text-[#272727]",
            `hover:${
              theme === "default"
                ? "bg-[rgba(184,159,91,0.10)]"
                : "bg-[rgba(255,255,255,0.04)]"
            }`
          )}
        >
          {Icon && mode === "onlyIcon" ? (
            <TooltipWrapper
              tooltip={label}
              variant={theme === "default" ? "secondary" : "dark"}
            >
              <NavIcon width="20" height="20" strokeWidth="1" />
            </TooltipWrapper>
          ) : (
            <NavIcon width="20" height="20" strokeWidth="1" />
          )}

          {mode === "default" && (
            <div
              className="ml-4"
              style={{
                fontSize: "12px",
                lineHeight: "20px",
                letterSpacing: "0.12px",
              }}
            >
              {label}
            </div>
          )}
        </LinkParent>
      </Link>
    )
  }
  return (
    <div>
      <LinkParent
        className={clsx(
          isActive &&
            (theme === "default"
              ? "bg-[rgba(184,159,91,0.20)] !text-[#272727]"
              : "bg-[rgba(255,255,255,0.08)] !text-[#FFFFF4]"),
          theme === "default" ? "hover:text-[#272727]" : "hover:text-[#FFFFF4]",
          mode === "default" &&
            "px-3 text-[#6D6D6D] pt-3.5 pr-3 pb-3.25 pl-3 w-[225px] min-h-[47px]",
          mode === "onlyIcon" && "justify-center text-[#6D6D6D]",
          `hover:${
            theme === "default"
              ? "bg-[rgba(184,159,91,0.10)]"
              : "bg-[rgba(255,255,255,0.04)]"
          }`
        )}
        onClick={onParentClick}
      >
        {Icon && mode === "onlyIcon" ? (
          <TooltipWrapper
            tooltip={label}
            variant={theme === "default" ? "secondary" : "dark"}
          >
            <NavIcon width="20" height="20" strokeWidth="1" />
          </TooltipWrapper>
        ) : (
          <NavIcon width="20" height="20" strokeWidth="1" />
        )}

        {mode === "default" && (
          <div
            className="ml-4 flex-grow"
            style={{
              fontSize: "12px",
              lineHeight: "20px",
              letterSpacing: "0.12px",
            }}
          >
            {label}
          </div>
        )}

        {mode === "default" && (
          <div onClick={toggleSubnav} className="p-1">
            {subnav ? (
              <AngleDownIcon />
            ) : (
              <AngleDownIcon className="-rotate-180" />
            )}
          </div>
        )}
      </LinkParent>

      {mode === "default" &&
        subnav &&
        // @ts-ignore
        props.children
          ?.filter((child) => !child.hidden)
          .map(({ label: childLabel, href }) => {
            const isChildActive = pathname === href
            return (
              <ChildLink
                key={href}
                href={href}
                onClick={handleNav}
                className={clsx(
                  isChildActive &&
                    (theme === "default"
                      ? "bg-[rgba(184,159,91,0.20)] !text-[#272727]"
                      : "bg-[rgba(255,255,255,0.04)] text-[#FFFFF4] hover:text-[#FFFFF4]"),
                  theme === "default"
                    ? "hover:bg-[rgba(184,159,91,0.20)]"
                    : "hover:text-[#FFFFF4] tracking-[0.12px] leading-[20px]"
                )}
              >
                {childLabel.includes("Inbound") ||
                childLabel.includes("Outbound") ? (
                  <>
                    {childLabel.slice(0, -3)}
                    <span>{childLabel.slice(-2).slice(0, -1)}</span>
                  </>
                ) : (
                  childLabel
                )}
              </ChildLink>
            )
          })}
    </div>
  )
}

export const SidebarFooter: FC<SidebarFooterProps> = ({
  href,
  label,
  icon: Icon,
  ...props
}) => {
  if (href) {
    return (
      <Link href={href} passHref legacyBehavior>
        <XSMed
          as="a"
          className="py-6 px-5 flex items-center text-charcoal bg-black bg-opacity-5"
          {...props}
        >
          {Icon && <Icon className="mr-2.5" />}
          {label}
        </XSMed>
      </Link>
    )
  }

  return (
    <XSMed
      as="a"
      className="py-6 px-5 flex items-center text-charcoal bg-black bg-opacity-5"
      {...props}
    >
      {Icon && <Icon className="mr-2.5" />}
      {label}
    </XSMed>
  )
}

export const SidebarMenu: FC<SidebarMenuProps> = ({
  links,
  footerLink,
  theme = "dark",
}) => {
  const { user, masquerade } = useAuth()
  const pathname = usePathname()
  const [menuValue, setMenuValue] = useState(
    links.find(
      (link) =>
        ("href" in link && pathname.includes(link.href)) ||
        ("children" in link &&
          link.children.some((child) => pathname.includes(child.href)))
    )?.label
  )

  const isMasqueradeAdmin = React.useMemo(() => {
    const myMasqueradeUser = masquerade?.active_users?.find(
      ({ email }) => email === user?.email
    )
    return (
      myMasqueradeUser?.access_type === "Admin" ||
      myMasqueradeUser?.access_type === "Owner"
    )
  }, [masquerade, user])

  return (
    <NavigationMenu.Root asChild orientation="vertical" value={menuValue}>
      <DashboardSidebar>
        <nav className="px-2">
          <NavigationMenu.List asChild>
            <XSMed as="ul" className="group">
              {links.map(
                ({ adminOnly = false, ...itemProps }) =>
                  (!adminOnly || isMasqueradeAdmin) && (
                    <DashboardLink
                      key={itemProps.label}
                      setMenuValue={setMenuValue}
                      theme={theme}
                      {...itemProps}
                    />
                  )
              )}
            </XSMed>
          </NavigationMenu.List>
        </nav>
        <div className="flex flex-col">
          {footerLink && <SidebarFooter {...footerLink} />}
        </div>
      </DashboardSidebar>
    </NavigationMenu.Root>
  )
}
