import type { SVGProps } from "react"

export const BurgerMenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M21 6H3V5H21V6ZM21 11H3V12H21V11ZM21 17H3V18H21V17Z"
      fill="currentColor"
    />
  </svg>
)
export default BurgerMenuIcon
