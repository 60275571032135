import { useEffect, useMemo } from "react"
import API from "@/lib/api"
import useAuth from "@/hooks/useAuth"
import { useAppDispatch, useAppSelector } from "@/store/store"
import { profileActions } from "@/store/profile/profileSlice"
import { profileSelectors } from "@/store/profile/selectors"

let abortController = new AbortController()
let isLoaded = false
export default function useProfileConnections() {
  const dispatch = useAppDispatch()
  const connectionResponse = useAppSelector(profileSelectors.connectionData)
  const { masquerade } = useAuth()

  useEffect(() => {
    const getProfileConnection = async () => {
      try {
        const { data: connectionsObject } = await API.get(
          "/api/personal/dashboard/connection",
          { signal: abortController.signal }
        )
        dispatch(profileActions.setConnectionData(connectionsObject))
        isLoaded = true
      } catch (error) {
        console.error("Failed to fetch profile details:", error)
      }
    }
    if (!isLoaded) {
      abortController = new AbortController()
      getProfileConnection()
    }

    return () => {
      abortController.abort("")
    }
  }, [dispatch])

  const connectionsByPlatform = useMemo(() => {
    const returnObject: any = {
      connectionFacebook: 0,
      connectionSpotify: 0,
      connectionInstagram: 0,
      connectionYouTube: 0,
      connectionTwitter: 0,
      connectionTikTok: 0,
    }
    if (!connectionResponse) {
      return returnObject
    }
    const totalConnectionsFacebook = Object.values(connectionResponse).reduce(
      (total, platform) => {
        if (typeof platform === "object" && platform !== null) {
          return total + (platform?.Facebook ?? 0)
        }
        return total
      },
      0
    )
    const totalConnectionsInstagram = Object.values(connectionResponse).reduce(
      (total, platform) => {
        if (typeof platform === "object" && platform !== null) {
          return total + (platform?.Instagram ?? 0)
        }
        return total
      },
      0
    )
    const totalConnectionsYouTube = Object.values(connectionResponse).reduce(
      (total, platform) => {
        if (typeof platform === "object" && platform !== null) {
          return total + (platform?.YouTube ?? 0)
        }
        return total
      },
      0
    )
    const totalConnectionsSpotify = Object.values(connectionResponse).reduce(
      (total, platform) => {
        if (typeof platform === "object" && platform !== null) {
          return total + (platform?.Spotify ?? 0)
        }
        return total
      },
      0
    )
    const totalConnectionsTwitter = Object.values(connectionResponse).reduce(
      (total, platform) => {
        if (typeof platform === "object" && platform !== null) {
          return total + (platform?.Twitter ?? 0)
        }
        return total
      },
      0
    )
    const totalConnectionsTikTok = Object.values(connectionResponse).reduce(
      (total, platform) => {
        if (typeof platform === "object" && platform !== null) {
          return total + (platform?.TikTok ?? 0)
        }
        return total
      },
      0
    )
    returnObject.connectionFacebook = totalConnectionsFacebook
    returnObject.connectionInstagram = totalConnectionsInstagram
    returnObject.connectionYouTube = totalConnectionsYouTube
    returnObject.connectionSpotify = totalConnectionsSpotify
    returnObject.connectionTwitter = totalConnectionsTwitter
    returnObject.connectionTikTok = totalConnectionsTikTok

    return returnObject
  }, [connectionResponse])

  const connection = useMemo(() => {
    if (!connectionResponse) {
      return null
    }
    const totalConnections = Object.values(connectionResponse).reduce(
      (total, platform) => {
        if (typeof platform === "object" && platform !== null) {
          const sum = Object.values(platform).reduce(
            (platformTotal: number, value: number) =>
              (platformTotal + value) as number,
            0
          )
          return total + sum
        }
        return total
      },
      0
    )
    return { ...connectionResponse, total: totalConnections }
  }, [connectionResponse])

  const connectionsForSingleProfile = useMemo(() => {
    if (!connectionResponse) {
      return null
    }
    const connectionsArray = Object.entries(connectionResponse).map(
      ([key, value]) => ({ key, value })
    )

    const connectionData = connectionsArray.find(
      (item) => item.key === masquerade?.slug
    )
    if (connectionData) {
      const totalValue = Object.values(connectionData.value).reduce(
        (acc: number, curr: number) => acc + curr,
        0
      )

      return {
        ...connectionData,
        totalValue,
      }
    }
    return null
  }, [connectionResponse, masquerade?.slug])

  return { connection, connectionsByPlatform, connectionsForSingleProfile }
}
