import type { FC, SVGProps } from "react"

export const ProfileIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M4.43027 16.1986C4.9372 15.0043 6.12079 14.1666 7.50002 14.1666H12.5C13.8793 14.1666 15.0628 15.0043 15.5698 16.1986M13.3334 7.91663C13.3334 9.75758 11.841 11.25 10 11.25C8.15907 11.25 6.66669 9.75758 6.66669 7.91663C6.66669 6.07568 8.15907 4.58329 10 4.58329C11.841 4.58329 13.3334 6.07568 13.3334 7.91663ZM18.3334 9.99996C18.3334 14.6023 14.6024 18.3333 10 18.3333C5.39765 18.3333 1.66669 14.6023 1.66669 9.99996C1.66669 5.39759 5.39765 1.66663 10 1.66663C14.6024 1.66663 18.3334 5.39759 18.3334 9.99996Z" />
  </svg>
)
export default ProfileIcon
