/* eslint-disable import/prefer-default-export */
export enum REQUEST_STATUS {
  NEW = "New",
  NEW_REQUEST = "New Request",
  IN_CONVERSATION = "In Conversation",
  UNDER_CONTRACT = "Under Contract",
  PENDING_SIGNATURE = "Pending Signature",
  PENDING_CONFIRMATION = "Pending Confirmation",
  ACTIVE = "Active",
  COMPLETED = "Completed",
  ARCHIVED = "Archived",
  IGNORED = "Ignored",
  REVOKED = "Revoked",
  IN_RESOLUTION = "In Resolution",
  CANCELED = "Canceled",
}

export enum REQUEST_SUB_STATUS {
  NEW = "New",
  GRANTED = "Granted",
  PROPOSAL_REQUEST = "Proposal Request",
  PROPOSAL_APPROVAL = "Proposal Approval",
  INITIALIZE_CONTRACT = "Initialize Contract",
  PROPOSAL_INIT_CONTRACT = "Proposal Init Contract",
}
