import { SVGProps } from "react"

export const PublishProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#FCFCFC" />
    <path
      d="M16.6663 23.3307H23.333C25.174 23.3307 26.6663 21.8383 26.6663 19.9974C26.6663 18.1564 25.174 16.6641 23.333 16.6641H16.6663M16.6663 23.3307C14.8254 23.3307 13.333 21.8383 13.333 19.9974C13.333 18.1564 14.8254 16.6641 16.6663 16.6641M16.6663 23.3307C18.5073 23.3307 19.9997 21.8383 19.9997 19.9974C19.9997 18.1564 18.5073 16.6641 16.6663 16.6641"
      stroke="black"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PublishProfileIcon
