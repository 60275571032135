import React from "react"
import { P } from "@/styles/Type"
import useAuth from "@/hooks/useAuth"
import Breadcrumb from "./Breadcrumb"

const HeaderTextDrawer = ({ theme }) => {
  const { user, masquerade } = useAuth()

  return (
    <div
      className={`${
        theme === "dark" ? "bg-transparent z-[99] w-[350px]" : ""
      } flex items-center gap-4 align-middle "`}
    >
      {masquerade?.name ? (
        <P as="div" className="text-medgray">
          <Breadcrumb
            separator="/"
            containerClasses="flex items-center gap-4 align-middle"
            listClasses="text-medgray"
            activeClasses="text-medgray"
            capitalizeLinks={false}
          />
        </P>
      ) : (
        <P className="text-medgray">
          Dashboard — Welcome back {user?.first_name} {user?.last_name}
        </P>
      )}
    </div>
  )
}

export default HeaderTextDrawer
