import type { SVGProps } from "react"

export const StarActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99935 1.66699L12.5743 6.88366L18.3327 7.72533L14.166 11.7837L15.1494 17.517L9.99935 14.8087L4.84935 17.517L5.83268 11.7837L1.66602 7.72533L7.42435 6.88366L9.99935 1.66699Z"
      fill="#FFFFF4"
      stroke="#FFFFF4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default StarActive
