import { Request } from "@/types/Request"
import { UnlockedProject } from "@/types/Project"

export enum EventsTypes {
  REQUEST_UPDATED = "REQUEST_UPDATED",
  PROJECT_UPDATED = "PROJECT_UPDATED",
  REFETCH_DASHBOARD_PROJECT = "REFETCH_DASHBOARD_PROJECT",
  REQUEST_CHANGED_CHAT_SERVER_ACTION = "REQUEST_CHANGED_CHAT_SERVER_ACTION",
  PAYMENT_CHANGED_CHAT_SERVER_ACTION = "PAYMENT_CHANGED_CHAT_SERVER_ACTION",
  CHANNEL_ARCHIVED = "CHANNEL_ARCHIVED",
  CHECK_SINGLE_OPPORTUNITY = "CHECK_SINGLE_OPPORTUNITY",
}

type Listener<T = any> = (data: T) => void

interface EventEmitterInterface {
  on(
    event: EventsTypes.REFETCH_DASHBOARD_PROJECT,
    listener: Listener<any>
  ): void
  on(event: EventsTypes.CHANNEL_ARCHIVED, listener: Listener<any>): void
  on(event: EventsTypes.REQUEST_UPDATED, listener: Listener<Request>): void
  on(
    event: EventsTypes.PROJECT_UPDATED,
    listener: Listener<UnlockedProject>
  ): void
  on(
    event: EventsTypes.REQUEST_CHANGED_CHAT_SERVER_ACTION,
    listener: Listener<{ id: number }>
  ): void
  on(
    event: EventsTypes.PAYMENT_CHANGED_CHAT_SERVER_ACTION,
    listener: Listener<{ id: number; status: any; amount: number }>
  ): void
  on(event: EventsTypes.CHECK_SINGLE_OPPORTUNITY, listener: Listener<any>): void
  off(event: EventsTypes.REQUEST_UPDATED, listener: Listener<Request>): void
  off(
    event: EventsTypes.PROJECT_UPDATED,
    listener: Listener<UnlockedProject>
  ): void
  off(
    event: EventsTypes.REFETCH_DASHBOARD_PROJECT,
    listener: Listener<any>
  ): void
  off(event: EventsTypes.CHANNEL_ARCHIVED, listener: Listener<any>): void
  off(
    event: EventsTypes.REQUEST_CHANGED_CHAT_SERVER_ACTION,
    listener: Listener<any>
  ): void
  off(
    event: EventsTypes.PAYMENT_CHANGED_CHAT_SERVER_ACTION,
    listener: Listener<{ id: number; status: any; amount: number }>
  ): void
  off(
    event: EventsTypes.CHECK_SINGLE_OPPORTUNITY,
    listener: Listener<any>
  ): void
  emit(event: EventsTypes.REQUEST_UPDATED, data: Request): void
  emit(event: EventsTypes.CHANNEL_ARCHIVED, data: any): void
  emit(event: EventsTypes.PROJECT_UPDATED, data: UnlockedProject): void
  emit(event: EventsTypes.REFETCH_DASHBOARD_PROJECT, data: any): void
  emit(event: EventsTypes.REQUEST_CHANGED_CHAT_SERVER_ACTION, data: any): void
  emit(
    event: EventsTypes.PAYMENT_CHANGED_CHAT_SERVER_ACTION,
    data: { id: number; status: any; amount: number }
  ): void
  emit(event: EventsTypes.CHECK_SINGLE_OPPORTUNITY, data: any): void
}

class EventEmitter implements EventEmitterInterface {
  private events: Record<string, Listener[]> = {}

  on(event, listener: Listener): void {
    if (!this.events[event]) {
      this.events[event] = []
    }
    this.events[event].push(listener)
  }

  off(event, listenerToRemove: Listener): void {
    if (!this.events[event]) {
      return
    }

    this.events[event] = this.events[event].filter(
      (listener) => listener !== listenerToRemove
    )
  }

  emit(event, data): void {
    if (!this.events[event]) {
      console.log(`No listeners for event: ${event}`)
      return
    }

    this.events[event].forEach((listener) => listener(data))
  }
}

export default new EventEmitter() as EventEmitterInterface
