import type { FC, SVGProps } from "react"

export const ProfileActive: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.42959 16.199C4.93653 15.0047 6.12012 14.167 7.49935 14.167H12.4993C13.8786 14.167 15.0622 15.0047 15.5691 16.199M13.3327 7.91699C13.3327 9.75794 11.8403 11.2503 9.99935 11.2503C8.1584 11.2503 6.66602 9.75794 6.66602 7.91699C6.66602 6.07604 8.1584 4.58366 9.99935 4.58366C11.8403 4.58366 13.3327 6.07604 13.3327 7.91699ZM18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337C5.39698 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39698 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003Z"
      stroke="#FFFFF4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse cx="9.99935" cy="7.91699" rx="3.33333" ry="3.75" fill="#FFFFF4" />
    <path
      d="M5.83398 14.5837L4.58398 15.8337C7.08398 17.917 9.00065 18.3337 10.0007 18.3337C11.0007 18.3337 12.084 18.3337 12.9173 17.917C13.7507 17.5003 15.4173 16.2503 15.4173 16.2503L15.0007 15.417L12.9173 14.167H7.91732L5.83398 14.5837Z"
      fill="#FFFFF4"
    />
  </svg>
)
export default ProfileActive
