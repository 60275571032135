import type { SVGProps } from "react"

export const Star = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 3L22.635 12.39L33 13.905L25.5 21.21L27.27 31.53L18 26.655L8.73 31.53L10.5 21.21L3 13.905L13.365 12.39L18 3Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default Star
