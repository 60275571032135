import type { FC, SVGProps } from "react"

export const TeamIconActiveDark: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.91602 10C9.98708 10 11.666 8.32107 11.666 6.25C11.666 4.17893 9.98708 2.5 7.91602 2.5C5.84495 2.5 4.16602 4.17893 4.16602 6.25C4.16602 8.32107 5.84495 10 7.91602 10Z"
      fill="#FFFFF4"
    />
    <path
      d="M13.3327 2.8898C14.5674 3.50343 15.416 4.77762 15.416 6.25C15.416 7.72238 14.5674 8.99657 13.3327 9.6102M14.9993 13.972C16.2589 14.5419 17.3931 15.4708 18.3327 16.6667M1.66602 16.6667C3.28809 14.6021 5.49033 13.3333 7.91602 13.3333C10.3417 13.3333 12.5439 14.6021 14.166 16.6667M11.666 6.25C11.666 8.32107 9.98708 10 7.91602 10C5.84495 10 4.16602 8.32107 4.16602 6.25C4.16602 4.17893 5.84495 2.5 7.91602 2.5C9.98708 2.5 11.666 4.17893 11.666 6.25Z"
      stroke="#FFFFF4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 17.0996H1.5C1.5 17.0996 3.15385 13.0996 8 13.0996C12.8462 13.0996 14 17.0996 14 17.0996Z"
      fill="#FFFFF4"
    />
  </svg>
)
export default TeamIconActiveDark
