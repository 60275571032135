import { REQUEST_STATUS, REQUEST_SUB_STATUS } from "@/types/RequestStatus"
import { Request } from "@/types/Request"

const getRequestStatusName = (status: REQUEST_STATUS, request: Request) => {
  switch (status) {
    case REQUEST_STATUS.NEW:
      return "New Request"
    case REQUEST_STATUS.IN_CONVERSATION:
      if (
        request.status === REQUEST_STATUS.IN_CONVERSATION &&
        [REQUEST_SUB_STATUS.NEW, REQUEST_SUB_STATUS.GRANTED].includes(
          request.sub_status
        )
      ) {
        return "New Request"
      }
      return "Proposal"
    case REQUEST_STATUS.UNDER_CONTRACT:
    case REQUEST_STATUS.PENDING_SIGNATURE:
    case REQUEST_STATUS.PENDING_CONFIRMATION:
      if (
        request.status === REQUEST_STATUS.IN_CONVERSATION &&
        [REQUEST_SUB_STATUS.NEW, REQUEST_SUB_STATUS.GRANTED].includes(
          request.sub_status
        )
      ) {
        return "Proposal"
      }
      return "Contract phase"
    case REQUEST_STATUS.ACTIVE:
      return "Open project"
    case REQUEST_STATUS.COMPLETED:
      return "Completed"
    case REQUEST_STATUS.ARCHIVED:
      return "Archived"
    default:
      return status
  }
}

const isUserInProjectPage = (pathName: string, projectSlug?: string) => {
  if (
    pathName.includes("/dashboard/outbound") ||
    pathName.includes("/dashboard/inbox")
  ) {
    return true
  }
  return (
    pathName.startsWith("/dashboard/projects/") &&
    pathName.includes(projectSlug)
  )
}

// eslint-disable-next-line import/prefer-default-export
export { getRequestStatusName, isUserInProjectPage }
