import type { SVGProps } from "react"

export const Notifications = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M11.6667 17.4998H8.33339M15.0001 6.6665C15.0001 5.34042 14.4733 4.06865 13.5356 3.13097C12.5979 2.19329 11.3261 1.6665 10.0001 1.6665C8.67397 1.6665 7.4022 2.19329 6.46452 3.13097C5.52684 4.06865 5.00006 5.34042 5.00006 6.6665C5.00006 9.24166 4.35045 11.0048 3.62478 12.171C3.01266 13.1547 2.7066 13.6466 2.71783 13.7838C2.73025 13.9357 2.76244 13.9937 2.88487 14.0845C2.99544 14.1665 3.49388 14.1665 4.49077 14.1665H15.5093C16.5062 14.1665 17.0047 14.1665 17.1152 14.0845C17.2377 13.9937 17.2699 13.9357 17.2823 13.7838C17.2935 13.6466 16.9875 13.1547 16.3753 12.171C15.6497 11.0048 15.0001 9.24166 15.0001 6.6665Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default Notifications
