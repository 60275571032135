import { SVGProps } from "react"

export const Applications = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M1.6665 9.99967L4.5415 4.25801C4.67948 3.98033 4.89219 3.74665 5.15571 3.58324C5.41922 3.41983 5.7231 3.33317 6.03317 3.33301H13.9665C14.2766 3.33317 14.5805 3.41983 14.844 3.58324C15.1075 3.74665 15.3202 3.98033 15.4582 4.25801L18.3332 9.99967M1.6665 9.99967V14.9997C1.6665 15.4417 1.8421 15.8656 2.15466 16.1782C2.46722 16.4907 2.89114 16.6663 3.33317 16.6663H16.6665C17.1085 16.6663 17.5325 16.4907 17.845 16.1782C18.1576 15.8656 18.3332 15.4417 18.3332 14.9997V9.99967M1.6665 9.99967L6.49988 9.99966L8.49988 12.4997H11.4999L13.4999 9.99966L18.3332 9.99967"
      stroke="#6D6D6D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default Applications
