import React from "react"
import * as Dialog from "@radix-ui/react-dialog"
import { useAppDispatch, useAppSelector } from "@/store/store"
import { commonSelectors } from "@/store/common/selectors"
import { commonActions } from "@/store/common/commonSlice"
import REDUX_MODAL_KEYS from "@/lib/modals"
import GptChat from "@/components/GptChat/GptChat"

const GptChatModal = React.memo(() => {
  const dispatch = useAppDispatch()
  const modalState = useAppSelector(commonSelectors.modals)

  const handleToggle = (open?: boolean) => {
    dispatch(
      commonActions.setModal({ key: REDUX_MODAL_KEYS.gptChat, visible: open })
    )
  }

  return (
    <Dialog.Root onOpenChange={handleToggle} open={modalState.gptChat}>
      <Dialog.Portal style={{ pointerEvents: "all" }}>
        {modalState?.gptChat && (
          <Dialog.Overlay className="fixed z-[102] inset-0 bg-black bg-opacity-50" />
        )}
        <Dialog.Content
          forceMount
          // style={{
          //   display: isOpenManage ? "block" : "none",
          //   marginRight: isOpenChat && isOpenManage ? "658px" : undefined,
          // }}
          className="fixed z-[103] animate-slide-in right-0 top-0"
        >
          <div className="flex justify-end bg-white">
            {modalState?.gptChat && <GptChat />}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
})

GptChatModal.displayName = "GptChatModal"

export default GptChatModal
