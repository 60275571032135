import type { FC, SVGProps } from "react"

export const PortfolioActive: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.42959 16.198C4.93653 15.0037 6.12012 14.166 7.49935 14.166H12.4993C13.8786 14.166 15.0622 15.0037 15.5691 16.198M13.3327 7.91602C13.3327 9.75696 11.8403 11.2493 9.99935 11.2493C8.1584 11.2493 6.66602 9.75696 6.66602 7.91602C6.66602 6.07507 8.1584 4.58268 9.99935 4.58268C11.8403 4.58268 13.3327 6.07507 13.3327 7.91602ZM18.3327 9.99935C18.3327 14.6017 14.6017 18.3327 9.99935 18.3327C5.39698 18.3327 1.66602 14.6017 1.66602 9.99935C1.66602 5.39698 5.39698 1.66602 9.99935 1.66602C14.6017 1.66602 18.3327 5.39698 18.3327 9.99935Z"
      stroke="#272727"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse cx="9.99935" cy="7.91602" rx="3.33333" ry="3.75" fill="#272727" />
    <path
      d="M5.83398 14.5827L4.58398 15.8327C7.08398 17.916 9.00065 18.3327 10.0007 18.3327C11.0007 18.3327 12.084 18.3327 12.9173 17.916C13.7507 17.4993 15.4173 16.2493 15.4173 16.2493L15.0007 15.416L12.9173 14.166H7.91732L5.83398 14.5827Z"
      fill="#272727"
    />
  </svg>
)
export default PortfolioActive
