"use client"

import Sidebar from "@/components/GlobalLayout/sidebar"
import Header from "@/components/GlobalLayout/header"
import React, { useCallback, useEffect, useLayoutEffect, useMemo } from "react"
import {
  buildNavigationLinks,
  HeaderConfig,
  LayoutConfig,
  NavigationConfig,
} from "@/lib/sidebarHelper"
import useProjects from "@/hooks/useProjects"
import { useAppDispatch, useAppSelector } from "@/store/store"
import { commonActions } from "@/store/common/commonSlice"
import { commonSelectors } from "@/store/common/selectors"
import useAuth from "@/hooks/useAuth"
import { useChatContext } from "stream-chat-react"
import { usePathname } from "next/navigation"
import { isUserInProjectPage } from "@/lib/utils"
import useToast from "@/lib/useToast"
import Link from "next/link"
import { ServerActionPayload } from "@/components/messaging/types"
import eventEmmiter, { EventsTypes } from "@/lib/eventEmmiter"
import API from "@/lib/api"
import { Request } from "@/types/Request"
import GptChat from "@/components/GptChat"
import MasqueradeSwitchLoader from "./MasqueradeSwitchLoader"

const LAYOUT_COLLAPSED_LS_KEY = "lclk"

interface Props {
  children: React.ReactNode
  navigationConfig: NavigationConfig
  headerConfig: HeaderConfig | undefined
  // eslint-disable-next-line react/require-default-props
  layoutConfig?: LayoutConfig
}

const GlobalLayout = React.memo(
  ({
    children,
    navigationConfig,
    headerConfig,
    layoutConfig = { theme: "default" },
  }: Props) => {
    const { user } = useAuth()
    const { client } = useChatContext()
    const { masquerade, clearMasquerade } = useAuth()
    const projectsData = useProjects()
    const dispatch = useAppDispatch()
    const isCollapsed = useAppSelector(commonSelectors.isCollapsed)
    const pathName = usePathname()
    const { addToast } = useToast()
    useEffect(() => {
      const handleChatEvent = async (event: any) => {
        if (
          event.type === "message.new" &&
          event.message?.message_type === "SERVER_ACTION"
        ) {
          // PaymentSchedule / Request
          const parsedMessage = JSON.parse(event.message.text)
          const actionPayload = parsedMessage?.payload as ServerActionPayload
          const actionContent = actionPayload?.content
          if (actionContent?.user?.id === user?.id) {
            return
          }
          if (actionContent?.source === "PaymentSchedule") {
            // addToast("Payment has been updated", "info")
            setTimeout(() => {
              eventEmmiter.emit(
                EventsTypes.PAYMENT_CHANGED_CHAT_SERVER_ACTION,
                {
                  id: actionContent.PaymentSchedule?.id,
                  status: actionContent.action?.status,
                  amount: actionContent.PaymentSchedule?.amount,
                }
              )
            }, 1500)
          } else if (actionContent?.source === "Request") {
            // console.log(
            //   "isUserInProjectPage",
            //   isUserInProjectPage(pathName, actionContent?.Project?.slug)
            // )
            if (isUserInProjectPage(pathName, actionContent?.Project?.slug)) {
              setTimeout(async () => {
                const { data: freshRequestData } = await API.get<Request>(
                  `/api/request/${actionContent.Request?.id}/`
                )
                eventEmmiter.emit(EventsTypes.REQUEST_UPDATED, freshRequestData)
                eventEmmiter.emit(
                  EventsTypes.REQUEST_CHANGED_CHAT_SERVER_ACTION,
                  {
                    id: actionContent.Request?.id,
                    status: actionContent.action?.status,
                    sub_status: actionContent.action?.sub_status,
                  }
                )
              }, 1500)
            } else {
              addToast(
                "",
                "success",
                <Link
                  href={`/dashboard/projects/${actionContent.Project?.slug}`}
                >
                  Request has been updated
                </Link>
              )
            }
          }
        }
      }
      client.on(handleChatEvent)
      return () => {
        client.off(handleChatEvent)
      }
    }, [addToast, client, pathName, user?.id])

    useEffect(() => {
      if (navigationConfig.variant === "personalDashboard") {
        clearMasquerade()
      }
    }, [clearMasquerade, navigationConfig.variant])

    useLayoutEffect(() => {
      try {
        const discoverPaths = [
          "/discover",
          "/projects",
          "/directory",
          "/search",
          "/dashboard/projects/",
        ]
        if (discoverPaths.some((path) => pathName.startsWith(path))) {
          dispatch(commonActions.setIsCollapsed(true))
        } else {
          dispatch(
            commonActions.setIsCollapsed(
              localStorage.getItem(LAYOUT_COLLAPSED_LS_KEY) === "1"
            )
          )
        }
      } catch (e) {
        console.log(e)
      }
    }, [dispatch, pathName])

    const handleToggleCollapse = useCallback(() => {
      dispatch(commonActions.setIsCollapsed(!isCollapsed))
      setTimeout(() => {
        try {
          localStorage.setItem(LAYOUT_COLLAPSED_LS_KEY, isCollapsed ? "0" : "1")
        } catch (e) {
          console.log(e)
        }
      }, 0)
    }, [dispatch, isCollapsed])

    const links = useMemo(() => {
      if (navigationConfig.variant === "dashboard") {
        return buildNavigationLinks({
          ...navigationConfig,
          inboundCount: projectsData?.inboundProjects?.current_projects ?? 0,
          outboundCount: projectsData?.outboundProject?.length ?? 0,
        })
      }
      return buildNavigationLinks(navigationConfig)
    }, [
      navigationConfig,
      projectsData?.inboundProjects?.current_projects,
      projectsData?.outboundProject?.length,
    ])

    return (
      <div>
        {navigationConfig.variant === "dashboard" && <GptChat />}
        <Sidebar
          theme={navigationConfig.theme}
          links={links}
          collapsed={isCollapsed}
          toggle={handleToggleCollapse}
        />
        <div
          className={`layout-content-wrapper ${layoutConfig?.theme === "dark" ? "bg-offblack" : "bg-white"} min-h-screen transition-all relative ${
            isCollapsed ? "sm:ml-[72px]" : "sm:ml-[249px]"
          }`}
        >
          <Header
            theme={
              pathName === "/projects/new" ||
              pathName === `/artist/${masquerade?.slug}/edit` ||
              pathName === `/brand/${masquerade?.slug}/edit` ||
              pathName === `/artist/${masquerade?.slug}/preference/edit` ||
              pathName === `/brand/${masquerade?.slug}/preference/edit`
                ? "default"
                : headerConfig?.theme ?? "default"
            }
          >
            {headerConfig?.children}
          </Header>
          <MasqueradeSwitchLoader />
          {children}
        </div>
      </div>
    )
  }
)

export default GlobalLayout
