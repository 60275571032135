import type { SVGProps } from "react"

export const MessageIconDark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C8.93679 18 7.92085 17.7923 6.99144 17.4149L6.96064 17.4024C6.89199 17.3745 6.84501 17.3556 6.80791 17.3417C6.78767 17.3341 6.77406 17.3294 6.76468 17.3264L6.7558 17.3237L6.75291 17.3229C6.7229 17.3162 6.7108 17.3148 6.68005 17.3148C6.66248 17.3148 6.63619 17.3175 6.46774 17.3456L3.48483 17.8428C3.3452 17.866 3.20941 17.8887 3.09494 17.8973C2.97319 17.9065 2.81107 17.9065 2.64556 17.8355C2.42941 17.7428 2.25716 17.5706 2.16445 17.3544C2.09346 17.1889 2.09347 17.0268 2.10266 16.9051C2.1113 16.7906 2.13396 16.6548 2.15725 16.5151L2.65439 13.5323C2.68247 13.3638 2.68517 13.3375 2.68517 13.32C2.68517 13.2892 2.68383 13.2771 2.6771 13.2471C2.6771 13.2471 2.67654 13.2444 2.67364 13.2353C2.67101 13.2271 2.66708 13.2157 2.66104 13.1994L2.65833 13.1921C2.6424 13.1497 2.61999 13.0944 2.58513 13.0086C2.20767 12.0792 2 11.0632 2 10ZM6.5 8C6.5 7.72386 6.72386 7.5 7 7.5H10.3333C10.6095 7.5 10.8333 7.72386 10.8333 8C10.8333 8.27614 10.6095 8.5 10.3333 8.5H7C6.72386 8.5 6.5 8.27614 6.5 8ZM6.5 10.9167C6.5 10.6405 6.72386 10.4167 7 10.4167H12.8333C13.1095 10.4167 13.3333 10.6405 13.3333 10.9167C13.3333 11.1928 13.1095 11.4167 12.8333 11.4167H7C6.72386 11.4167 6.5 11.1928 6.5 10.9167Z"
      fill="black"
    />
  </svg>
)
export default MessageIconDark
